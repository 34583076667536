import * as React from "react";
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng
} from "use-places-autocomplete";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';


const Places = ({onChangeAddress, onSelect, className, ...addr}) => {
	const {
		ready,
		setValue,
		suggestions: {status, data},
		clearSuggestions,
	} = usePlacesAutocomplete();

	const handleChangePlaces = () => {
		let place = `${addr.fdCEP} ${addr.fdRua} ${addr.fdNum} ${addr.fdBai} ${addr.fdCid} ${addr.fdUF}`;
		place = place.trim();
		if(place){
			setValue(place);
		}
	};

	const handleSelectFullAddress = async (address) => {
		setValue(address, false);
		clearSuggestions();
		const results = await getGeocode({address});
		const {lat, lng} = await getLatLng(results[0]);
		onSelect({"lat": lat, "lng": lng});
	};

	return (
		<fieldset className={`dropdown ${(className) ? className : ''}`} disabled={!ready}>
			<Row className="mb-2 g-2">
				<Col xs={4}>
					<Form.Floating className="mb-1">
						<Form.Control id="ap-cep" type="text" placeholder="CEP" value={addr.fdCEP} onChange={(e) => onChangeAddress('fdCEP', e.target.value)} />
						<label htmlFor="ap-cep">CEP</label>
					</Form.Floating>
				</Col>
				<Col xs={6}>
					<Form.Floating className="mb-1">
						<Form.Control id="ap-rua" type="text" placeholder="Rua" value={addr.fdRua} onChange={(e) => onChangeAddress('fdRua', e.target.value)} />
						<label htmlFor="ap-rua">Rua</label>
					</Form.Floating>
				</Col>
				<Col xs={2}>
					<Form.Floating className="mb-1">
						<Form.Control id="ap-num" type="text" placeholder="Número" value={addr.fdNum} onChange={(e) => onChangeAddress('fdNum', e.target.value)} />
						<label htmlFor="ap-num">Número</label>
					</Form.Floating>
				</Col>
				<Col xs={4}>
					<Form.Floating className="mb-1">
						<Form.Control id="ap-bairro" type="text" placeholder="Bairro" value={addr.fdBai} onChange={(e) => onChangeAddress('fdBai', e.target.value)} />
						<label htmlFor="ap-bairro">Bairro</label>
					</Form.Floating>
				</Col>
				<Col xs={4}>
					<Form.Floating className="mb-1">
						<Form.Control id="ap-cid" type="text" placeholder="Cidade" value={addr.fdCid} onChange={(e) => onChangeAddress('fdCid', e.target.value)} />
						<label htmlFor="ap-cid">Cidade</label>
					</Form.Floating>
				</Col>
				<Col xs={4}>
					<Form.Floating className="mb-1">
						<Form.Control id="ap-uf" type="text" placeholder="Estado" value={addr.fdUF} onChange={(e) => onChangeAddress('fdUF', e.target.value)} />
						<label htmlFor="ap-uf">Estado</label>
					</Form.Floating>
				</Col>
			</Row>
			<Dropdown onToggle={(show, e) => (show && handleChangePlaces())} onSelect={(v, e) => handleSelectFullAddress(v)}>
				<Dropdown.Toggle variant="link" type="button" id="parse-place-latlng" className="btn-normal text-secondary px-0 text-left" size="lg">
					Transformar em área de ponderação
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-300">
					{(status === "OK") && data.map(({place_id, description}) =>
						<Dropdown.Item key={place_id} eventKey={description}>{description}</Dropdown.Item>
					)}
				</Dropdown.Menu>
			</Dropdown>
		</fieldset>
	);
};


export default Places;