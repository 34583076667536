import * as React from "react";
import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Wrapper } from "@googlemaps/react-wrapper";
import Map from './Map';
import Marker from './Marker';
import Places from './Places';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import santanderlogo from './images/Santander_logo.PNG';


const LocationView = ({globals, zoom, center, markers, simulations, handleLatLng, onIdleMap, onChangeAp, onChangeAddress, onChangeLatLng, onChangeQuestion, onSelectSim, ...rest}) => {
	const GOOGLE_MAPS_API_KEY = 'AIzaSyAuuHlcRFcvHmnCe0xQhVsBngj0pmTu1Iw';
	
	const [showUpFile, setShowUpFile] = useState(false);
	const refUpFile = useRef(null);
	const [geoView, setGeoView] = useState('ap');
	let navigate = useNavigate();

	return (
		<Container fluid className="location-view">
			<Wrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={['places']} render={(status) => <h2>{status}...</h2>}>
				<Row>
					<Col md={5} className="location-form-wrapper">
						<div className="p-3">
							<img src={santanderlogo} className="App-logo" alt="logo" />
						</div>
						<div className="location-form-inner px-5">
							<h2 className="mt-5 mb-3 fw-600">Potencial de transações</h2>
							<h4 className="mt-3 mb-5 fw-normal">Calcule o potencial de transações da localização do seu correspondente bancário</h4>
							<h5 className="mb-3 mt-5">Para comerçar a simular, informe:</h5>
							<Row className="g-2 my-2">
								<Col md={6} lg={6}>
									<Form.Select id="geo-view-select" value={geoView} className="mb-1 fw-500" onChange={(e) => setGeoView(e.target.value)}>
										<option value="ap">Área de ponderação</option>
										<option value="latlng">Latitude/Longitude</option>
										<option value="end">Endereço</option>
									</Form.Select>
								</Col>
							</Row>
							<Form.Floating className={`mb-3 ${(geoView === 'ap') ? '' : 'd-none'}`}>
								<Form.Control id="area-ponderacao" type="text" className="one-line-input" placeholder="A área de ponderação" value={rest.fdAP} onChange={(e) => onChangeAp(e.target.value)} />
								<label htmlFor="area-ponderacao">A área de ponderação</label>
								<span className={`input-closer ${(rest.fdAP) ? '' : 'd-none'}`} onClick={(e) => onChangeAp('')}>x</span>
							</Form.Floating>
							<Places
								className={`mb-3 ${(geoView === 'end') ? '' : 'd-none'}`}
								fdCEP={rest.fdCEP}
								fdRua={rest.fdRua}
								fdNum={rest.fdNum}
								fdBai={rest.fdBai}
								fdCid={rest.fdCid}
								fdUF={rest.fdUF}
								onChangeAddress={onChangeAddress}
								onSelect={(latlng) => handleLatLng(latlng)}
							/>
							<fieldset className={`mt-2 mb-3 g-2 ${(geoView === 'latlng') ? '' : 'd-none'}`}>
								<Row className="mb-2 g-2">
									<Col xs={4}>
										<Form.Floating className="mb-1">
											<Form.Control
												id="ap-lat"
												type="number"
												placeholder="Latitude"
												size="sm"
												value={markers[0].lat}
												onChange={(e) => onChangeLatLng('lat', e.target.value)}
											/>
											<label htmlFor="ap-lat">Latitude</label>
										</Form.Floating>
									</Col>
									<Col xs={4}>
										<Form.Floating className="mb-1">
											<Form.Control
												id="ap-lng"
												type="number"
												placeholder="Longitude"
												size="sm"
												value={markers[0].lng}
												onChange={(e) => onChangeLatLng('lng', e.target.value)}
											/>
											<label htmlFor="ap-lng">Longitude</label>
										</Form.Floating>
									</Col>
								</Row>
								<div>
									<Button
										variant="link"
										type="button"
										id="parse-latlng-ap"
										className="btn-normal text-secondary px-0 text-left"
										size="lg"
										onClick={() => {
											if(markers[0].lat && markers[0].lng){
												handleLatLng(markers[0]);
											}
										}}
									>
										Transformar em área de ponderação
									</Button>
								</div>
							</fieldset>
							<Row className="mt-4 mb-1 g-2">
								<Col xs={6}>
									<Dropdown className="d-grid" onSelect={(eventKey, e) => onSelectSim(e, eventKey)}>
										<Dropdown.Toggle variant="outline-red" type="button" id="carrega-sim" size="lg">
											Carregar simulação
										</Dropdown.Toggle>
										<Dropdown.Menu className="dropdown-300">
											{simulations.map((item, i) => 
												<Dropdown.Item key={i} eventKey={item.val}>{item.label}</Dropdown.Item>
											)}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
								<Col xs={6} className="d-grid">
									<Button
										variant="red"
										type="button"
										id="goto-formview"
										size="lg"
										onClick={() => {
											globals.setLoading(true);
											$.post({
												"url": globals.wsurl+'/verify_ap',
												"headers": {
													"Authorization": globals.bearer,
													"Content-Type": "application/json"
												},
												"data": JSON.stringify({"cod_ap": rest.fdAP}),
												"success": (rsp) => {
													globals.setLoading(false);
													(rsp.status) ? navigate("/form") : alert(rsp.msg);
												},
												"error": () => {
													globals.setLoading(false);
													alert('Algo aconteceu ao validar a área de ponderação e não podemos continuar. Tente novamente mais tarde.');
												}
											});
										}}
									>
										Avançar
									</Button>
									{/* <Link to="/form" className="btn btn-red btn-lg" onClick={() => alert('teste')}>Avançar</Link> */}
								</Col>
							</Row>
							<Form.Text>
								Se preferir, <span className="text-decoration-underline fw-500 cur-pointer" onClick={() => setShowUpFile(true)}>
								carregue uma simulação de um arquivo csv</span> ou navegue no mapa ao lado para escolher uma localização
							</Form.Text>
						</div>
					</Col>
					<Col className="px-0">
						<Map
							zoom={zoom}
							center={center}
							onClick={(e) => handleLatLng(e.latLng.toJSON())}
							onIdle={onIdleMap}
							style={{flexGrow: "1", height: "100%"}}
						>
							{markers.map((latLng, i) => (
								latLng.lat && latLng.lng &&
								<Marker key={i} position={latLng} />
							))}
						</Map>
					</Col>
				</Row>
			</Wrapper>
			<Modal show={showUpFile} onHide={() => setShowUpFile(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Upload de simulação</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="upload-csv-sims">
						<Form.Label>Selecione o arquivo CSV que contém a simulação</Form.Label>
						<Form.Control type="file" placeholder="Clique e selecione" ref={refUpFile} />
						<Form.Text className="text-muted">
							Clique em "carregar" para finalizar o processo
						</Form.Text>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="default" onClick={() => setShowUpFile(false)}>Cancelar</Button>
					<Button
						variant="red"
						onClick={() => {
							if(refUpFile.current.files.length){
								var reader = new FileReader();
								reader.onload = function(e){
									var json = formated_csv_to_json(e.target.result);
									console.log(json);
									json = JSON.parse(json);
									for(var k in json){
										if(/q\d+/.test(k)){
											onChangeQuestion(k, json[k]);
										}
										else if(['CEP', 'Rua', 'Num', 'Bai', 'Cid', 'UF'].includes(k)){
											onChangeAddress('fd'+k, json[k]);
										}
										else if(k === "AP"){
											onChangeAp(json[k]);
										}
										else if(k === "markers"){
											handleLatLng(json[k][0]);
										}
									}
									setShowUpFile(false);
								};
								reader.readAsText(refUpFile.current.files[0]);
							}
						}}
					>
						Carregar
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
};


function formated_csv_to_json(csv){
	var linhas = csv.split('\n').filter((l) => l !== '');
	linhas = linhas.map((l) => {
		l = l.split(';');
		// Multivalue fields
		if(['q7', 'q8', 'q13', 'q19', 'q27'].includes(l[0])){
			let [k, ...a] = l;
			return `"${k}": ${JSON.stringify(a)}`;
		}
		else if(l[0] === 'q2'){
			let tempo = l[1].split('-');
			return `"q2": {"ini": "${tempo[0].trim()}", "fim": "${tempo[1].trim()}"}`;
		}
		else if(l[0] === 'lat/lng'){
			let lat = "";
			let lng = "";
			for(var i=1; i<l.length; i++){
				if(l[i].includes('lat')){
					lat = Number(l[i].replace('lat:', '').trim());
				}
				else if(l[i].includes('lng')){
					lng = Number(l[i].replace('lng:', '').trim());
				}
			}
			return `"markers": [{"lat": ${lat}, "lng": ${lng}}]`;
		}
		else{
			return `"${l[0]}": "${l[1]}"`;
		}
	});

	return `{${linhas.join(', ')}}`;
}

export default LocationView;