import * as React from "react";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import santanderlogo from './images/Santander_logo.PNG';


const FormView = ({markers, onChangeAp, onChangeAddress, onChangeLatLng, onChangeQuestion, onResetQuestions, onClickCalcPredict, onClickSaveSim, ...rest}) => {
	const [panel, setPanel] = useState('panel-collapsed');
	const refNomeSim = useRef(null);
	
	const section1 = "Características gerais e Funcionamento do ponto";
	const section2 = "Serviços disponíveis";
	const section3 = "Estrutura e Localização"
	const section4 = "Funcionários e Treinamento";

	const disbdCaractGerais = !(rest.fdAP);
	const disbdServDisp = (disbdCaractGerais || !(rest.q1 && rest.q2.ini && rest.q2.fim && rest.q3 && rest.q4 && rest.q5 && rest.q6));
	const disbdEstrLocaliz = (disbdServDisp || !(rest.q7.length && rest.q8.length && rest.q9));
	const disbdFuncTreino = (disbdEstrLocaliz || !(
		rest.q10 && rest.q11 && rest.q12 && rest.q13.length && rest.q14 && rest.q15 && rest.q16 && rest.q17 &&
		rest.q18 && rest.q19.length && rest.q20 && rest.q21 && rest.q22 && rest.q23 && rest.q24
	));

	return (
		<Container fluid className={`form-view ${panel}`}>
			<div className="form-panel-bg"></div>
			<div className="form-panel">
				<Row className="h-100">
					<Col xs="auto" className="form-panel-sections">
						<div className={`form-panel-sections-inner  ${(disbdCaractGerais) ? 'justify-content-end' : ''}`}>
							<div className={`form-panel-controller ${(disbdCaractGerais) ? 'section-disabled' : ''}`}>
								<div className="form-panel-controller-icon icon1" title={section1}></div>
								<div className="form-panel-controller-text">{section1}</div>
							</div>
							<div className={`form-panel-controller ${(disbdServDisp) ? 'section-disabled' : ''}`}>
								<div className="form-panel-controller-icon icon2" title={section2}></div>
								<div className="form-panel-controller-text">{section2}</div>
							</div>
							<div className={`form-panel-controller ${(disbdEstrLocaliz) ? 'section-disabled' : ''}`}>
								<div className="form-panel-controller-icon icon3" title={section3}></div>
								<div className="form-panel-controller-text">{section3}</div>
							</div>
							<div className={`form-panel-controller ${(disbdFuncTreino) ? 'section-disabled' : ''}`}>
								<div className="form-panel-controller-icon icon4" title={section4}></div>
								<div className="form-panel-controller-text">{section4}</div>
							</div>
							<Row className={`my-1 g-2 ${(panel === '') ? 'invisible': ''}`}>
								<Col xs={6} className="d-grid">
									<Button
										variant="link-red"
										type="button"
										size="lg"
										onClick={() => onResetQuestions()}
									>
										Apagar
									</Button>
								</Col>
								<Col xs={6} className="d-grid">
									<Button
										variant="red"
										type="button"
										size="lg"
										onClick={() => {
											setPanel('');
											!disbdFuncTreino && onClickCalcPredict();
										}}
									>
										Avançar
									</Button>
								</Col>
							</Row>
						</div>
					</Col>
					<Col className="form-panel-results">
						<div className="form-panel-results-inner">
							<h1 className="mb-5">Pontencial de transações:</h1>
							<div className="d-flex align-items-center px-4">
								<span className="ap-result me-4">{rest.qtdeTransacoes.padStart(5, '0')}</span><span className="fs-4 fw-500">Transações/mês</span>
							</div>
							<InputGroup className="mb-3 px-4 pt-4" size="lg">
								<Form.Control
									ref={refNomeSim}
									placeholder="Nome da simulação"
									aria-label="Nome da simulação"
									aria-describedby="Para salvar a simulação"
								/>
								<Button variant="red" id="save-sim" onClick={() => onClickSaveSim(refNomeSim.current.value)}>Salvar</Button>
							</InputGroup>
							<div className="px-4 text-end">
								<span className="text-decoration-underline fw-500 cur-pointer" onClick={() => export_to_csv_file({markers, ...rest})}>
									<i className="fa fa-download me-1"></i>
									Baixar simulação
								</span>
							</div>
							<Row className="mt-5 p-4 g-2 mx-0">
								<Col xs={6} className="d-grid">
									<Button variant="outline-secondary" type="button" size="lg" onClick={() => setPanel('panel-collapsed')}>
										Voltar
									</Button>
								</Col>
								<Col xs={6} className="d-grid">
									<Link to="/" className="btn btn-outline-secondary btn-lg">Tela inicial</Link>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</div>
			<div className="form-lacation">
				<div className="p-3">
					<a href="https://santander.simulador.bigdata.com.br">
						<img src={santanderlogo} className="App-logo" alt="logo" />
					</a>
				</div>
				<Row className="py-4 px-3">
					<Col className="px-lg-4">
						<Row className="g-2">
							<Col md={12}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-ap"
										className="one-line-input"
										placeholder="A área de ponderação"
										value={rest.fdAP}
										onChange={(e) => onChangeAp(e.target.value)}
									/>
									<label htmlFor="fv-ap">A área de ponderação</label>
									<span className={`input-closer ${(rest.fdAP) ? '' : 'd-none'}`} onClick={(e) => onChangeAp('')}>x</span>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="number"
										id="fv-lat"
										placeholder="Latitude"
										value={markers[0].lat}
										onChange={(e) => onChangeLatLng('lat', e.target.value)}
									/>
									<label htmlFor="fv-lat">Latitude</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="number"
										id="fv-lng"
										placeholder="Longitude"
										value={markers[0].lng}
										onChange={(e) => onChangeLatLng('lng', e.target.value)}
									/>
									<label htmlFor="fv-lng">Longitude</label>
								</Form.Floating>
							</Col>
						</Row>
					</Col>
					<Col className="px-lg-4">
						<Row className="g-2">
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-cep"
										value={rest.fdCEP}
										placeholder="CEP"
										onChange={(e) => onChangeAddress('fdCEP', e.target.value)}
									/>
									<label htmlFor="fv-cep">CEP</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={6}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-rua"
										value={rest.fdRua}
										placeholder="Rua"
										onChange={(e) => onChangeAddress('fdRua', e.target.value)}
									/>
									<label htmlFor="fv-rua">Rua</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={2}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-num"
										value={rest.fdNum}
										placeholder="Número"
										onChange={(e) => onChangeAddress('fdNum', e.target.value)}
									/>
									<label htmlFor="fv-num">Número</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-bairro"
										value={rest.fdBai}
										placeholder="Bairro"
										onChange={(e) => onChangeAddress('fdBai', e.target.value)}
									/>
									<label htmlFor="fv-bairro">Bairro</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="fv-cid"
										value={rest.fdCid}
										placeholder="Cidade"
										onChange={(e) => onChangeAddress('fdCid', e.target.value)}
									/>
									<label htmlFor="fv-cid">Cidade</label>
								</Form.Floating>
							</Col>
							<Col md={6} lg={4}>
								<Form.Floating className="mb-1">
									<Form.Control
										type="text"
										id="ap-uf"
										value={rest.fdUF}
										placeholder="Estado"
										onChange={(e) => onChangeAddress('fdUF', e.target.value)}
									/>
									<label htmlFor="ap-uf">Estado</label>
								</Form.Floating>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			<Row className="form-inner justify-content-sm-center">
				<Col xs={10}>
					<h3 className={`py-5 mb-0 text-red fw-bold ${(disbdCaractGerais) ? 'section-disabled' : ''}`}>{section1}</h3>
					<fieldset className={`caracteristicas-gerais ${(disbdCaractGerais) ? 'section-disabled' : ''}`} disabled={disbdCaractGerais}>
						<div className="mb-4">
							<h6 className="mb-0 py-2">1. Quantidade de funcionários do estabelecimento.</h6>
							<Form.Text>Apenas números</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="number"
										id="fv-qtde-funcs"
										name="qtde-funcs"
										value={rest.q1}
										placeholder="------"
										min={0}
										onChange={(e) => onChangeQuestion('q1', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">2. Qual o horário de funcionamento do ponto?</h6>
							<Form.Text>(Exemplo: 9h-18h)</Form.Text>
							<Row className="g-2 align-items-center">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-func-hora-ini"
										name="func-hora-ini"
										value={rest.q2.ini}
										placeholder="00:00"
										onChange={(e) => onChangeQuestion('q2', {"ini": e.target.value, "fim": rest.q2.fim})}
									/>
								</Col>
								&nbsp;às&nbsp;
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-func-hora-fim"
										name="func-hora-fim"
										value={rest.q2.fim}
										placeholder="00:00"
										onChange={(e) => onChangeQuestion('q2', {"ini": rest.q2.ini, "fim": e.target.value})}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">3. Em qual período do dia a procura pelos serviços é maior?</h6>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Select id="fv-per-serv-maior" name="per-serv-maior" value={rest.q3} onChange={(e) => onChangeQuestion('q3', e.target.value)}>
										<option>Selecione uma opção</option>
										<option>No período da manhã</option>
										<option>Durante a tarde</option>
										<option>Durante a noite</option>
										<option>Madrugada</option>
										<option>A busca é igual durante o dia todo</option>
										<option>Não sei responder</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">4. O ponto funciona aos feriados?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-ferds-sim"
										name="func-ferds"
										value="Sim"
										label="Sim"
										checked={rest.q4 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q4', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-ferds-nao"
										name="func-ferds"
										value="Não"
										label="Não"
										checked={rest.q4 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q4', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">5. O ponto funciona aos domingos?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-dom-sim"
										name="func-dom"
										value="Sim"
										label="Sim"
										checked={rest.q5 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q5', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-dom-nao"
										name="func-dom"
										value="Não"
										label="Não"
										checked={rest.q5 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q5', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">6. Em qual período do mês a procura pelos serviços de CORBAN é maior?</h6>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Select id="fv-per-mes-cobran-maior" name="per-mes-cobran-maior" value={rest.q6} onChange={(e) => onChangeQuestion('q6', e.target.value)}>
										<option>Selecione uma opção</option>
										<option>Na primeira semana</option>
										<option>Entre a segunda e a terceira semana</option>
										<option>Na última semana</option>
										<option>A busca é igual ao longo do mês</option>
										<option>Não sei responder</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
					</fieldset>
					<h3 className={`py-5 mb-0 text-red fw-bold ${(disbdServDisp) ? 'section-disabled' : ''}`}>{section2}</h3>
					<fieldset className={`servicos-disponiveis ${(disbdServDisp) ? 'section-disabled' : ''}`} disabled={disbdServDisp}>
						<div className="mb-4">
							<h6 className="mb-0 py-2">7. Quais produtos são oferecidos no ponto?</h6>
							<Form.Check
								type="checkbox"
								id="fv-prod-ofer-boleto"
								name="prod-ofer-boleto"
								value="Pagamento de boleto"
								label="Pagamento de boleto"
								checked={rest.q7.includes("Pagamento de boleto")}
								onChange={(e) => onChangeQuestion('q7', handle_checkbox_change(rest.q7, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-prod-ofer-recarga"
								name="prod-ofer-recarga"
								value="Recarga"
								label="Recarga"
								checked={rest.q7.includes("Recarga")}
								onChange={(e) => onChangeQuestion('q7', handle_checkbox_change(rest.q7, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-prod-ofer-contas"
								name="prod-ofer-contas"
								value="Abertura de contas"
								label="Abertura de contas"
								checked={rest.q7.includes("Abertura de contas")}
								onChange={(e) => onChangeQuestion('q7', handle_checkbox_change(rest.q7, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-prod-ofer-saque"
								name="prod-ofer-saque"
								value="Saque"
								label="Saque"
								checked={rest.q7.includes("Saque")}
								onChange={(e) => onChangeQuestion('q7', handle_checkbox_change(rest.q7, e))}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-prod-ofer-outros"
										name="prod-ofer-outro"
										value={extract_other_value(rest.q7)}
										placeholder="OUTRO"
										onChange={(e) => onChangeQuestion('q7',  handle_other_change(rest.q7, e))}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">8. Qual o ramo de atividade do ponto?</h6>
							<Form.Check
								type="checkbox"
								id="fv-ramo-ponto-revistas"
								name="ramo-ponto-revistas"
								value="Banca de revistas"
								label="Banca de revistas"
								checked={rest.q8.includes("Banca de revistas")}
								onChange={(e) => onChangeQuestion('q8', handle_checkbox_change(rest.q8, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ramo-ponto-mercado"
								name="ramo-ponto-mercado"
								value="Mercado"
								label="Mercado"
								checked={rest.q8.includes("Mercado")}
								onChange={(e) => onChangeQuestion('q8', handle_checkbox_change(rest.q8, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ramo-ponto-padaria"
								name="ramo-ponto-padaria"
								value="Padaria"
								label="Padaria"
								checked={rest.q8.includes("Padaria")}
								onChange={(e) => onChangeQuestion('q8', handle_checkbox_change(rest.q8, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ramo-ponto-roupas"
								name="ramo-ponto-roupas"
								value="Loja de roupas"
								label="Loja de roupas"
								checked={rest.q8.includes("Loja de roupas")}
								onChange={(e) => onChangeQuestion('q8', handle_checkbox_change(rest.q8, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-ramo-ponto-eletros"
								name="ramo-ponto-eletros"
								value="Loja de Eletrônicos"
								label="Loja de Eletrônicos"
								checked={rest.q8.includes("Loja de Eletrônicos")}
								onChange={(e) => onChangeQuestion('q8', handle_checkbox_change(rest.q8, e))}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-ramo-ponto-outros"
										name="ramo-ponto-outro"
										value={extract_other_value(rest.q8)}
										placeholder="OUTRO"
										onChange={(e) => onChangeQuestion('q8', handle_other_change(rest.q8, e))}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">9. Qual a forma que os usuários chegam até os serviços bancários oferecidos?</h6>
							<Form.Check
								type="radio"
								id="fv-forma-chegam-serv-estabele"
								name="forma-chegam-serv"
								value="Chegam ao estabelecimento com o intuito de realizar o serviço"
								label="Chegam ao estabelecimento com o intuito de realizar o serviço"
								checked={rest.q9 === "Chegam ao estabelecimento com o intuito de realizar o serviço"}
								onChange={(e) => e.target.checked && onChangeQuestion('q9', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fv-forma-chegam-serv-estacion"
								name="forma-chegam-serv"
								value="Vão ao estabelecimento para fazer outra atividade e, por conveniência, acabam utilizando os serviços bancários"
								label="Vão ao estabelecimento para fazer outra atividade e, por conveniência, acabam utilizando os serviços bancários"
								checked={rest.q9 === "Vão ao estabelecimento para fazer outra atividade e, por conveniência, acabam utilizando os serviços bancários"}
								onChange={(e) => e.target.checked && onChangeQuestion('q9', e.target.value)}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-forma-chegam-serv-outros"
										name="forma-chegam-serv-outro"
										placeholder="OUTRO"
										value={rest.q9.includes('[outro]') ? rest.q9.replace('[outro]', '') : ''}
										onChange={(e) => onChangeQuestion('q9', (e.target.value) ? `[outro]${e.target.value}` : '')}
									/>
								</Col>
							</Row>
						</div>
					</fieldset>
					<h3 className={`py-5 mb-0 text-red fw-bold ${(disbdEstrLocaliz) ? 'section-disabled' : ''}`}>{section3}</h3>
					<fieldset className={`estrutura-localizacao ${(disbdEstrLocaliz) ? 'section-disabled' : ''}`} disabled={disbdEstrLocaliz}>
						<div className="mb-4">
							<h6 className="mb-0 py-2">10. Tem material do Santander na fachada/porta?</h6>
							<Form.Check
								type="radio"
								id="fv-material-porta-banco"
								name="material-porta"
								value="Sim, fornecido pelo banco"
								label="Sim, fornecido pelo banco"
								checked={rest.q10 === "Sim, fornecido pelo banco"}
								onChange={(e) => e.target.checked && onChangeQuestion('q10', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fv-material-porta-estabelec"
								name="material-porta"
								value="Sim, feito pelo estabelecimento"
								label="Sim, feito pelo estabelecimento"
								checked={rest.q10 === "Sim, feito pelo estabelecimento"}
								onChange={(e) => e.target.checked && onChangeQuestion('q10', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fv-material-porta-nao-propag"
								name="material-porta"
								value="Não há propaganda"
								label="Não há propaganda"
								checked={rest.q10 === "Não há propaganda"}
								onChange={(e) => e.target.checked && onChangeQuestion('q10', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">11. Tem material do Santander no caixa?</h6>
							<Form.Check
								type="radio"
								id="material-caixa-banco"
								name="material-caixa"
								value="Sim, fornecido pelo banco"
								label="Sim, fornecido pelo banco"
								checked={rest.q11 === "Sim, fornecido pelo banco"}
								onChange={(e) => e.target.checked && onChangeQuestion('q11', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="material-caixa-estabelec"
								name="material-caixa"
								value="Sim, feito pelo estabelecimento"
								label="Sim, feito pelo estabelecimento"
								checked={rest.q11 === "Sim, feito pelo estabelecimento"}
								onChange={(e) => e.target.checked && onChangeQuestion('q11', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="material-caixa-nao-propag"
								name="material-caixa"
								value="Não há propaganda"
								label="Não há propaganda"
								checked={rest.q11 === "Não há propaganda"}
								onChange={(e) => e.target.checked && onChangeQuestion('q11', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">12. Tem material do Santander em outra parte da loja?</h6>
							<Form.Check
								type="radio"
								id="material-outra-parte-banco"
								name="material-outra-parte"
								value="Sim, fornecido pelo banco"
								label="Sim, fornecido pelo banco"
								checked={rest.q12 === "Sim, fornecido pelo banco"}
								onChange={(e) => e.target.checked && onChangeQuestion('q12', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="material-outra-parte-estabelec"
								name="material-outra-parte"
								value="Sim, feito pelo estabelecimento"
								label="Sim, feito pelo estabelecimento"
								checked={rest.q12 === "Sim, feito pelo estabelecimento"}
								onChange={(e) => e.target.checked && onChangeQuestion('q12', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="material-outra-parte-nao-propag"
								name="material-outra-parte"
								value="Não há propaganda"
								label="Não há propaganda"
								checked={rest.q12 === "Não há propaganda"}
								onChange={(e) => e.target.checked && onChangeQuestion('q12', e.target.value)}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">13. É ou já foi CORBAN de outro banco? Se sim, de qual banco?</h6>
							<Form.Check
								type="checkbox"
								id="fv-corban-outros-nao"
								name="corban-outros-nao"
								value="Não"
								label="Não"
								checked={rest.q13.includes("Não")}
								onChange={(e) => onChangeQuestion('q13', handle_checkbox_change(rest.q13, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-corban-outros-itau"
								name="corban-outros-itau"
								value="Sim, Itaú"
								label="Sim, Itaú"
								checked={rest.q13.includes("Sim, Itaú")}
								onChange={(e) => onChangeQuestion('q13', handle_checkbox_change(rest.q13, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-corban-outros-bb"
								name="corban-outros-bb"
								value="Sim, Banco do Brasil"
								label="Sim, Banco do Brasil"
								checked={rest.q13.includes("Sim, Banco do Brasil")}
								onChange={(e) => onChangeQuestion('q13', handle_checkbox_change(rest.q13, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-corban-outros-bradesco"
								name="corban-outros-bradesco"
								value="Sim, Bradesco"
								label="Sim, Bradesco"
								checked={rest.q13.includes("Sim, Bradesco")}
								onChange={(e) => onChangeQuestion('q13', handle_checkbox_change(rest.q13, e))}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-corban-outros-outros"
										name="corban-outros-outro"
										value={extract_other_value(rest.q13)}
										placeholder="OUTRO"
										onChange={(e) => onChangeQuestion('q13', handle_other_change(rest.q13, e))}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">14. Dentro do estabelecimento onde é feito o atendimento?</h6>
							<Form.Check
								type="radio"
								id="fv-atend-feito-caixa"
								name="atend-feito"
								value="Caixa"
								label="Caixa"
								checked={rest.q14 === "Caixa"}
								onChange={(e) => e.target.checked && onChangeQuestion('q14', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fv-atend-feito-balcao"
								name="atend-feito"
								value="Posição de Atendimento/Balcão"
								label="Posição de Atendimento/Balcão"
								checked={rest.q14 === "Posição de Atendimento/Balcão"}
								onChange={(e) => e.target.checked && onChangeQuestion('q14', e.target.value)}
							/>
							<Form.Check
								type="radio"
								id="fv-atend-feito-exclusivo"
								name="atend-feito"
								value="Espaço exclusivo dedicado ao atendimento dos serviços de CORBAN"
								label="Espaço exclusivo dedicado ao atendimento dos serviços de CORBAN"
								checked={rest.q14 === "Espaço exclusivo dedicado ao atendimento dos serviços de CORBAN"}
								onChange={(e) => e.target.checked && onChangeQuestion('q14', e.target.value)}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-atend-feito-outros"
										name="atend-feito-outro"
										placeholder="OUTRO"
										value={rest.q14.includes('[outro]') ? rest.q14.replace('[outro]', '') : ''}
										onChange={(e) => onChangeQuestion('q14', (e.target.value) ? `[outro]${e.target.value}` : '')}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">15. Possui serviço de segurança no estabelecimento?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-seg-estabelec-sim"
										name="seg-estabelec"
										value="Sim"
										label="Sim"
										checked={rest.q15 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q15', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-seg-estabelec-nao"
										name="seg-estabelec"
										value="Não"
										label="Não"
										checked={rest.q15 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q15', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">16. O ponto está localizado em uma</h6>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Select id="fv-localizado-em" name="localizado-em" value={rest.q16} onChange={(e) => onChangeQuestion('q16', e.target.value)}>
										<option>Selecione uma opção</option>
										<option>Área Residencial</option>
										<option>Área Comercial</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">17. A loja fica em uma área com fluxo intenso de pedestres?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-fluxo-peds-sim"
										name="fluxo-peds"
										value="Sim"
										label="Sim"
										checked={rest.q17 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q17', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-fluxo-peds-nao"
										name="fluxo-peds"
										value="Não"
										label="Não"
										checked={rest.q17 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q17', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">18. A rua tem iluminação pública?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-ilum-pub-sim"
										name="ilum-pub"
										value="Sim"
										label="Sim"
										checked={rest.q18 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q18', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-ilum-pub-nao"
										name="ilum-pub"
										value="Não"
										label="Não"
										checked={rest.q18 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q18', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">19. Existe algum estabelecimento de mobilidade em até 1 km do ponto?</h6>
							<Form.Check
								type="checkbox"
								id="fv-estab-mobde-ponibus"
								name="estab-mobde-ponibus"
								value="Ponto de ônibus"
								label="Ponto de ônibus"
								checked={rest.q19.includes("Ponto de ônibus")}
								onChange={(e) => onChangeQuestion('q19', handle_checkbox_change(rest.q19, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-estab-mobde-tonibusmun"
								name="estab-mobde-tonibusmun"
								value="Terminal de ônibus municipais"
								label="Terminal de ônibus municipais"
								checked={rest.q19.includes("Terminal de ônibus municipais")}
								onChange={(e) => onChangeQuestion('q19', handle_checkbox_change(rest.q19, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-estab-mobde-trodov"
								name="estab-mobde-trodov"
								value="Terminal rodoviário"
								label="Terminal rodoviário"
								checked={rest.q19.includes("Terminal rodoviário")}
								onChange={(e) => onChangeQuestion('q19', handle_checkbox_change(rest.q19, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-estab-mobde-ptaxi"
								name="estab-mobde-ptaxi"
								value="Ponto de táxi"
								label="Ponto de táxi"
								checked={rest.q19.includes("Ponto de táxi")}
								onChange={(e) => onChangeQuestion('q19', handle_checkbox_change(rest.q19, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-estab-mobde-aero"
								name="estab-mobde-aero"
								value="Aeroporto"
								label="Aeroporto"
								checked={rest.q19.includes("Aeroporto")}
								onChange={(e) => onChangeQuestion('q19', handle_checkbox_change(rest.q19, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-estab-mobde-nao"
								name="estab-mobde-nao"
								value="Não"
								label="Não"
								checked={rest.q19.includes("Não")}
								onChange={(e) => onChangeQuestion('q19', handle_checkbox_change(rest.q19, e))}
							/>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">20. Qual a distância até um Shopping mais próximo?</h6>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Select id="fv-shop-prox" name="shop-prox" value={rest.q20} onChange={(e) => onChangeQuestion('q20', e.target.value)}>
										<option>Selecione uma opção</option>
										<option>O ponto fica em um shopping</option>
										<option>Até uma quadra</option>
										<option>De uma a três quadras</option>
										<option>De três a cinco quadras</option>
										<option>De cinco a dez quadras</option>
										<option>Mais de dez quadras</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">21. Qual a distância até o estacionamento privado mais próximo?</h6>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Select id="fv-estacionamento-prox" name="estacionamento-prox" value={rest.q21} onChange={(e) => onChangeQuestion('q21', e.target.value)}>
										<option>Selecione uma opção</option>
										<option>Existe um estacionamento privado no ponto</option>
										<option>Menos de 1 km</option>
										<option>Mais de 1 km</option>
										<option>Não tem estacionamento na região</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">22. Existem restaurantes ou lanchonetes próximos ao ponto?</h6>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Select id="fv-rest-lancho-prox" name="rest-lancho-prox" value={rest.q22} onChange={(e) => onChangeQuestion('q22', e.target.value)}>
										<option>Selecione uma opção</option>
										<option>Sim, o ponto é uma lanchonete ou restaurante</option>
										<option>Sim, em menos de 1 quadra</option>
										<option>Sim, entre 1 e 3 quadras</option>
										<option>Sim, entre 3 e 5 quadras</option>
										<option>Não, mais de 5 quadras</option>
										<option>Não existem estabelecimentos alimentícios na região</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">23. Qual a distãncia até o caixa eletrônico mais próximo ao ponto?</h6>
							<Form.Text>(Banco Santander, Banco 24h e outros Bancos)</Form.Text>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Select id="fv-caixa-eletro-prox" name="caixa-eletro-prox" value={rest.q23} onChange={(e) => onChangeQuestion('q23', e.target.value)}>
										<option>Selecione uma opção</option>
										<option>Existe um caixa eletrônico no ponto</option>
										<option>Menos de 1 quadra</option>
										<option>De 2 a 5 quadras</option>
										<option>De 5 a 10 quadras</option>
										<option>Mais de 10 quadras</option>
										<option>Não há caixa eletrônico na região</option>
									</Form.Select>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">24. O caixa eletrônico mais próximo funciona 24h?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-caixa-eletro-24h-sim"
										name="caixa-eletro-24h"
										value="Sim"
										label="Sim"
										checked={rest.q24 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q24', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-caixa-eletro-24h-nao"
										name="caixa-eletro-24h"
										value="Não"
										label="Não"
										checked={rest.q24 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q24', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
					</fieldset>
					<h3 className={`py-5 mb-0 text-red fw-bold ${(disbdFuncTreino) ? 'section-disabled' : ''}`}>{section4}</h3>
					<fieldset className={`funcionarios-treinamento ${(disbdFuncTreino) ? 'section-disabled' : ''}`} disabled={disbdFuncTreino}>
						<div className="mb-4">
							<h6 className="mb-0 py-2">25. Os funcionários informam que o estabelecimento realiza serviços bancários?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-funcs-info-serv-banco-sim"
										name="funcs-info-serv-banco"
										value="Sim"
										label="Sim"
										checked={rest.q25 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q25', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-funcs-info-serv-banco-nao"
										name="funcs-info-serv-banco"
										value="Não"
										label="Não"
										checked={rest.q25 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q25', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">26. Os funcionários receberam algum treinamento pra operar os serviços de CORBAN?</h6>
							<Row className="g-2">
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-funcs-treino-serv-banco-sim"
										name="funcs-treino-serv-banco"
										value="Sim"
										label="Sim"
										checked={rest.q26 === "Sim"}
										onChange={(e) => e.target.checked && onChangeQuestion('q26', e.target.value)}
									/>
								</Col>
								<Col md={6} lg={2}>
									<Form.Check
										type="radio"
										id="fv-funcs-treino-serv-banco-nao"
										name="funcs-treino-serv-banco"
										value="Não"
										label="Não"
										checked={rest.q26 === "Não"}
										onChange={(e) => e.target.checked && onChangeQuestion('q26', e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<div className="mb-4">
							<h6 className="mb-0 py-2">27. Qual a frequência dos treinamentos?</h6>
							<Form.Check
								type="checkbox"
								id="fv-freq-funcs-treino-uma"
								name="freq-funcs-treino-uma"
								value="Apenas uma vez"
								label="Apenas uma vez"
								checked={rest.q27.includes("Apenas uma vez")}
								onChange={(e) => onChangeQuestion('q27', handle_checkbox_change(rest.q27, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-freq-funcs-treino-trimestral"
								name="freq-funcs-treino-trimestral"
								value="Trimestral"
								label="Trimestral"
								checked={rest.q27.includes("Trimestral")}
								onChange={(e) => onChangeQuestion('q27', handle_checkbox_change(rest.q27, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-freq-funcs-treino-semestral"
								name="freq-funcs-treino-semestral"
								value="Semestral"
								label="Semestral"
								checked={rest.q27.includes("Semestral")}
								onChange={(e) => onChangeQuestion('q27', handle_checkbox_change(rest.q27, e))}
							/>
							<Form.Check
								type="checkbox"
								id="fv-freq-funcs-treino-aual"
								name="freq-funcs-treino-aual"
								value="Anual"
								label="Anual"
								checked={rest.q27.includes("Anual")}
								onChange={(e) => onChangeQuestion('q27', handle_checkbox_change(rest.q27, e))}
							/>
							<Row className="g-2">
								<Col md={6} lg={4}>
									<Form.Control
										type="text"
										id="fv-freq-funcs-treino-outros"
										name="freq-funcs-treino-outros"
										value={extract_other_value(rest.q27)}
										placeholder="OUTRO"
										onChange={(e) => onChangeQuestion('q27', handle_other_change(rest.q27, e))}
									/>
								</Col>
							</Row>
						</div>
					</fieldset>
				</Col>
			</Row>
		</Container>
	);
};

function handle_checkbox_change(arr, e){
	let [...arrnew] = arr;
	if(e.target.checked){
		arrnew[arrnew.length] = e.target.value; // add value at end
	}
	else{
		arrnew.splice(arrnew.indexOf(e.target.value), 1);
	}

	return arrnew;
}

function extract_other_value(q){
	let foundv = q.find((v) => v.includes('[outro]'));

	return (foundv) ? foundv.replace('[outro]', '') : '';
}

function handle_other_change(arr, e){
	let [...arrnew] = arr;
	let i = arrnew.findIndex((v) => v.includes('[outro]'));
	i = (i !== -1) ? i : arr.length;
	if(e.target.value){
		arrnew[i] = `[outro]${e.target.value}`;
	}
	else{
		arrnew.splice(i, 1);
	}

	return arrnew;
}

function export_to_json_file(jsonData){
	let dataStr = JSON.stringify(jsonData);
	let dataUri = 'data:application/json;charset=utf-8,'+encodeURIComponent(dataStr);
	let exportFileDefaultName = 'simulacao.json';
	let a = document.createElement('a');
	a.setAttribute('href', dataUri);
	a.setAttribute('download', exportFileDefaultName);
	a.click();
}

function export_to_csv_file(jsonData){
	let dataStr = json_to_formated_csv(jsonData);
	let dataUri = 'data:text/csv;charset=utf-8,'+encodeURIComponent(dataStr);
	let exportFileDefaultName = 'simulacao.csv';
	let a = document.createElement('a');
	a.setAttribute('href', dataUri);
	a.setAttribute('download', exportFileDefaultName);
	a.click();
}

function json_to_formated_csv(json){
	var linhas = [];
	var num_answer_cols = 2; // initially 2, because of lat/lng have 2 answer cols
	// Multivalue fields can extend the number of answer columns, so we redefine this number
	['q7', 'q8', 'q13', 'q19', 'q27'].forEach((k) => {
		if(json[k].length > num_answer_cols){
			num_answer_cols = json[k].length;
		}
	});
	var total_cols = num_answer_cols + 1;
	for(var k in json){
		var a = [];
		if(k === 'markers'){
			a = ['lat/lng', `lat:${json[k][0]["lat"]}`, `lng:${json[k][0]["lng"]}`];
		}
		else if(k === 'q2'){
			a = [k, `${json[k]["ini"]} - ${json[k]["fim"]}`];
		}
		else if(json[k].constructor === Array){
			a = [k, ...json[k]];
		}
		else{
			a = [k.replace('fd', ''), json[k]];
		}
		while(a.length < total_cols){
			a.push('');
		}
		linhas[linhas.length] = a.join(';');
	}

	return linhas.join('\n');
}

export default FormView;