import { useState, useEffect } from "react";


const Marker = (options) => {
	const [marker, setMarker] = useState();

	useEffect(() => {
		if(!marker){
			setMarker(new window.google.maps.Marker());
		}

		// remove marker from map on unmount
		return () => {
			if(marker){
				marker.setMap(null);
			}
		};
	}, [marker]);

	useEffect(() => {
		if(marker){
			const lat = parseFloat(options.position.lat);
			const lng = parseFloat(options.position.lng);
			if(!isNaN(lat) && !isNaN(lng)){
				options.position.lat = lat;
				options.position.lng = lng;
				marker.setOptions(options);
			}
		}
	}, [marker, options]);

	return null;
};


export default Marker;